<template>
  <validation-observer ref="resetPass">
    <div>
      <div class="container-sm-lot pl-1 pr-1 ctx-rex mt-2">
        <div class="cards ctx-card w-100 my-2">
          <div class="card-body">
            <div v-if="check == false">
              <h3 class="font-weight-bolder text-primary mb-0 text-center">
                ตั้งรหัสผ่านใหม่
              </h3>

              <p class="mb-0 text-primary text-center">
                กรุณากรอกรหัสผ่านใหม่
              </p>

              <div class="mt-1">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-form-input
                    v-model="password"
                    class="form-valid"
                    :state="errors.length > 0 ? false:null"
                    placeholder="กรุณากรอกรหัสผ่าน"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="mt-1">
                <validation-provider
                  #default="{ errors }"
                  name="confirmPassword"
                  rules="required"
                >
                  <b-form-input
                    v-model="confirmPassword"
                    class="form-valid"
                    :state="errors.length > 0 ? false:null"
                    placeholder="กรุณากรอกรหัสผ่านอีกครั้ง"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>

              <div class="text-center">
                <button
                  class="btn-forgot mt-2 mb-1"
                  @click="confirmPass()"
                >
                  ยืนยัน
                </button>
              </div>
            </div>
            <div v-else-if="check == true">
              <h4 class="text-center">
                Link Expired
              </h4>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->
    </div>
  </validation-observer>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, confirmed,
} from '@validations'

export default {
  name: 'Home',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
  },
  data() {
    return {
      required,
      confirmed,
      password: '',
      confirmPassword: '',
      role: null,
      check: null,
    }
  },
  created() {
  },
  mounted() {
    this.CheckTokenReset()
  },
  methods: {
    CheckTokenReset() {
      this.$http
        .get(`lotto-suvarn/CheckTokenReset/${this.$route.params.token}`)
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          // console.log(ress.data)
          this.check = false
        })
        .catch(err => {
          this.check = true
          // console.log(err.response.data.message)
          if (err.response.data.message) {
            // this.$router.push({ name: 'home' })
          }
          // this.SwalError(err.response.data.message)
        })
    },
    confirmPass() {
      this.$refs.resetPass.validate().then(success => {
        if (success) {
          if (this.password !== this.confirmPassword) {
            this.SwalError('รหัสผ่านไม่ตรงกัน')
          } else {
            const data = {
              password: this.password,
            }
            this.$http
              .post(`/lotto-suvarn/changepass/${this.$route.params.token}`, data)
              .then(res => {
                if (res) {
                  this.Success('เปลี่ยนรหัสผ่านสำเร็จ')
                  this.$router.push({ name: 'home' })
                } else {
                  this.SwalError('เปลี่ยนรหัสผ่านไม่สำเร็จ')
                }
              })
          }
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">Success</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">Error !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped></style>
